import Referral from '@components/Referral';
import { getConterPartyLogo, getConterPartyName } from '@helpers/constants';
import { HeadTagData } from '@helpers/headTagData';
import { getOpportunityBySlug } from '@services/opportunities.service';
import NextHeadTag from '@ui/molecules/NextHeadTag';
import { useEffect } from 'react';
import { logEvent, moengageEventConstants } from '@services/moengage';
import { isUnauthorizedRequest } from '@services/identity.service';

const login = ({ opportunityDetails }) => {
    useEffect(() => {
        logEvent({ eventName: moengageEventConstants.VIEWED_LOGIN_PAGE });
    }, []);

    return (
        <>
            <NextHeadTag {...HeadTagData['/login']} />
            <Referral
                showEmail={false}
                opportunityDetails={opportunityDetails}
                login={true}
            />
        </>
    );
};

export const getServerSideProps = async (ctx) => {
    const { req, query } = ctx;

    let opportunityDetails = null;
    const slug = query?.opportunity;

    if (!(await isUnauthorizedRequest(req.cookies.AUTH, ctx))) {
        return {
            redirect: {
                permanent: false,
                destination: '/opportunities',
            },
            props: {},
        };
    }
    if (slug) {
        const response = await getOpportunityBySlug(slug);
        if (response.status) {
            const opportunity = response.entity;

            if (opportunity) {
                opportunityDetails = {
                    opportunityId: opportunity.id,
                    opportunityTitle: opportunity.title,
                    opportunityName: getConterPartyName(opportunity),
                    opportunityLogo: getConterPartyLogo(opportunity),
                };
            }
        }
    }
    return {
        props: { opportunityDetails },
    };
};

export default login;
